@media only screen and (max-width: 500px) {
  .hidesmall {
    display: none !important;
  }
  .spec-dropdown {
    list-style: none;
  }
}

.drop-inline {
  flex: 0 0 auto;
  display: inline-flex;
  z-index: 501;
}

.virtuallist1 {
  padding-top: 50px;
}

.drop-inline .dropdown-menu {
  left: 0px !important;
  right: unset !important;
  z-index: 501;
}

.mainm.drop-inline .dropdown-menu {
  left: unset !important;
  right: 10px !important;
  z-index: 501;
}

.spec-dropdown.upward > div {
  bottom: 0px !important;
  right: 5px;
  top: unset !important;
  z-index: 501;
}

#quiller.modal-body {
  display: flex;
  flex-direction: column;
}

.chat-application .sidebar-content.showall {
  transform: translateX(0%) !important;
  position: relative;
}

.modal-contentabs .modal-content {
  position: absolute !important;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
}

/* .modal-content {
  border-radius: 0px !important;
} */

.modal-contentfit .modal-content {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
}

.modal-contentwide {
  width: calc(100% - 1rem) !important;
  max-width: 800px !important;
}

.helpmodal.helpmodal.helpmodal {
  max-height: 700px !important;
}

.helpmodal .modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal-contentabssmaller {
  max-height: 700px;
}

.modal-contentabssmaller .modal-content {
  height: 95%;
  max-height: 700px;
  width: 320px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.modal-contentabssmaller.wider .modal-content {
  height: 95%;
  max-height: 700px;
  max-width: 500px;
  width: 95%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.modal-contentabssmaller.exelist .modal-content {
  height: 95%;
  max-height: 700px;
  height: calc(var(--vh) * 100 - 2rem);
  width: calc(100% - 1rem);
  max-width: 420px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.modal-contentabssmaller.footers .modal-content {
  height: 95%;
  max-height: unset;
  height: calc(var(--vh) * 100 - 3rem);
  width: calc(100% - 1rem);
  max-width: 800px !important;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.modal-contentabssmaller.footers {
  overflow: hidden;
  /* margin-top: 0px !important; */
}

.modal-dialog-centered.small {
  min-height: 600px !important;
}

.z-501 {
  z-index: 501;
}

.chat-exercise .content-area-wrapper {
  flex-direction: column;
}

.maxwidth800 {
  margin-left: 0px;
  margin-right: 0px;
  height: calc(
    var(--vh, 1vh) * 100 - env(safe-area-inset-bottom) -
      env(safe-area-inset-top)
  ) !important;
  background: white;
  position: relative;
}

#mainverloader.paging::after {
  content: "";
  opacity: 0.7;
  background-image: url("gif.svg");
  background-size: auto;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 0;
}

#mainverloader.paging.reversedturn::after {
  content: "";
  opacity: 0.7;
  background-image: url("gif.svg");
  background-size: auto;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  transform: rotate(180deg);
  z-index: 0;
}

#toolbar .divider.text {
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
  overflow: unset;
}

#quiller .ql-color.ql-picker.ql-color-picker {
  height: 36px;
}

#write .content {
  min-height: unset;
}

.crossed {
  background: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      to top right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    );
}

.modal-dialog-centered {
  height: calc(
    100% - env(safe-area-inset-top) - env(safe-area-inset-bottom)
  ) !important;
  min-height: 200px !important;
}

.modal-dialog-centered.prompter {
  height: calc(
    100% - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 10px
  ) !important;
  min-height: 200px !important;
}

.modal-dialog-centered.prompter.showhelp {
  margin-left: 0px;
}

@media (min-width: 900px) {
  .modal-dialog-centered.prompter.showhelp {
    margin-left: calc(50% - 200px - 250px);
  }
}

@media (min-width: 1100px) {
  .modal-dialog-centered.prompter.showhelp {
    margin-left: 150px;
  }
}

.modal-content {
  border-radius: 0px !important;
}

.modal-dialog.insertdialog {
  margin-top: calc(0.5rem + env(safe-area-inset-top));
  margin-bottom: calc(0.5rem + env(safe-area-inset-bottom));
  height: calc(
    100% - 1rem - env(safe-area-inset-top) - env(safe-area-inset-bottom)
  ) !important;
  margin-right: auto;
  margin-left: auto;
}

.modal-dialog.insertdialog.isfixed.isfixed {
  position: fixed !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  transform: none !important;
  max-width: 100% !important;
  max-height: 100% !important;
  margin: 0px !important;
  margin-top: env(safe-area-inset-top) !important;
  margin-bottom: env(safe-area-inset-bottom) !important;
  height: calc(
    100% - env(safe-area-inset-top) - env(safe-area-inset-bottom)
  ) !important;
}

.modal-dialog.isfixedhelper.isfixedhelper.isfixedhelper {
  position: fixed !important;
  top: calc(50% + calc(env(safe-area-inset-top) / 2)) !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  transform: translateY(-50%) !important;
  margin-top: 0px !important;
  margin-bottom: env(safe-area-inset-bottom) !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  height: calc(
    100% - env(safe-area-inset-bottom) - env(safe-area-inset-top) - 18px
  ) !important;
}

.modal-dialog.insertdialog.isfixed .modal-content {
  border-radius: 0px !important;
}

.newbook {
  max-width: 500px !important;
  min-height: 550px !important;
}

.modal-dialog.middlesizeold {
  transition: all 0.2s !important;
  max-width: 500px;
  margin: 0px !important;
  transform: translate(-50%, -50%) !important;
  left: 50%;
  top: 50%;
}

.modal-dialog.middlesizeold.bookpublisher.showhelp {
  max-width: 800px;
  margin: calc(env(safe-area-inset-top)) 5px calc(env(safe-area-inset-bottom))
    0px;
  transform: translate(0%, -50%) !important;
  left: 0%;
  top: 50%;
}

@media (min-width: 1100px) {
  .modal-dialog.middlesizeold.bookpublisher.showhelp {
    left: 0px;
  }
}

.modal-dialog.bookpublisher {
  max-height: calc(100% - 20px) !important;
}

@media (min-width: 576px) {
  .modal-dialog.middlesizeold {
    max-width: 560px;
  }
}

@media (min-width: 800px) {
  .modal-dialog.middlesizeold.middlesizeold.middlesizeold {
    max-width: 800px;
  }
}

@media (max-width: 576px) {
  .middlesizeold .row.form-group {
    margin-bottom: 0px !important;
  }
}

.xgallery .modal-content {
  height: calc(100% - 60px);
}

.modal-dialog.middlesize2 {
  max-height: 800px;
  max-width: 500px;
  margin: calc(0rem + env(safe-area-inset-top)) 0px
    calc(0rem + env(safe-area-inset-bottom)) 0px;
  transform: translateY(-50%) !important;
  top: calc(50% - calc(env(safe-area-inset-top) / 2));
}

.modal-dialog.middlesize2 {
  margin-left: 0px !important;
}

.modal-dialog.middlesize2.showhelp {
  margin-left: 0px;
}

@media (min-width: 500px) {
  .modal-dialog.middlesize2 {
    margin-left: calc(50% - 250px) !important;
  }
}

@media (min-width: 500px) {
  .modal-dialog.middlesize2.showhelp {
    margin-left: 0px !important;
  }
}

@media (min-width: 900px) {
  .modal-dialog.middlesize2.showhelp {
    margin-left: calc(50% - 450px) !important;
  }
}

@media (min-width: 1100px) {
  .modal-dialog.middlesize2.showhelp {
    margin-left: 150px !important;
  }
}

#creatorxa.creatorish {
  max-width: 100% !important;
  height: 100% !important;
  margin: 0px !important;
  border: none !important;
}

#creatorxa.creatorish .modal-content {
  border: 0px solid white;
}

.modal-dialog.middlesize.creatorish {
  max-width: 750px;
  margin: calc(0.5rem + env(safe-area-inset-top)) auto
    calc(0.5rem + env(safe-area-inset-bottom)) auto;
  height: calc(
    100% - 1rem - env(safe-area-inset-top) - env(safe-area-inset-bottom)
  ) !important;
}

.modal-dialog.middlesize.creatorish {
  margin: calc(env(safe-area-inset-top)) auto calc(env(safe-area-inset-bottom))
    auto;
  height: calc(
    100% - env(safe-area-inset-top) - env(safe-area-inset-bottom)
  ) !important;
}

@media (min-width: 500px) {
  .modal-dialog.middlesize.creatorish {
    margin: calc(env(safe-area-inset-top)) auto
      calc(env(safe-area-inset-bottom)) auto;
    height: calc(
      100% - env(safe-area-inset-top) - env(safe-area-inset-bottom)
    ) !important;
  }
}

@media (min-width: 576px) {
  .modal-dialog.middlesize.creatorish {
    margin: calc(0.5rem + env(safe-area-inset-top)) auto
      calc(0.5rem + env(safe-area-inset-bottom)) auto;
    height: calc(
      100% - 1rem - env(safe-area-inset-top) - env(safe-area-inset-bottom)
    ) !important;
  }
}

@media (max-width: 576px) {
  .modal-dialog.middlesize.creatorish .modal-content {
    border-radius: 0px !important;
  }
}

/* @media (max-width: 576px) {
  .modal-dialog.middlesize.insertdialog.clipboard .modal-content {
    border-radius: 0px !important;
    flex: 1 1 auto;
  }
  .modal-dialog.middlesize.insertdialog.clipboard {
    max-width: 576px;
    margin: calc(env(safe-area-inset-top)) auto
      calc(env(safe-area-inset-bottom)) auto;
    height: calc(
      100% - env(safe-area-inset-top) - env(safe-area-inset-bottom)
    ) !important;
  }
} */

.modal-dialog.middlesize.middlesize.middlesize {
  margin: calc(env(safe-area-inset-top)) auto calc(env(safe-area-inset-bottom))
    calc(50% - 400px) !important;
  transform: translateY(-50%) !important;
  top: 50%;
  height: 95% !important;
  transition: all 0.2s !important;
  max-width: 800px;
}

@media (max-width: 800px) {
  .modal-dialog.middlesize.middlesize.middlesize {
    height: 100% !important;
    margin: calc(env(safe-area-inset-top)) 0px calc(env(safe-area-inset-bottom))
      0px !important;
    top: 0px;
    transform: translateY(0%) !important;
  }
}

@media (min-width: 800px) {
  .modal-dialog.middlesize.middlesize.middlesize.showhelp {
    transform: translateY(-50%) !important;
    margin: calc(env(safe-area-inset-top)) auto
      calc(env(safe-area-inset-bottom)) 0px !important;
  }
}

@media (min-width: 800px) {
  .modal-dialog.middlesize.middlesize.middlesize.clipleft {
    max-width: 790px;
  }
}

@media (max-width: 576px) {
  .middlesize .row.form-group {
    margin-bottom: 0px !important;
  }
}

@media (max-width: 576px) {
  .invisb576 {
    display: none;
  }
}

/* @media (min-width: 750px) {
  .modal-dialog.middlesize {
    max-width: 700px;
    margin: calc(0.5rem + env(safe-area-inset-top)) auto
      calc(0.5rem + env(safe-area-inset-bottom)) auto;
  }
} */

.modal-dialog-centered.insertdialog .modal-content {
  overflow-y: hidden !important;
  flex: 1 1 auto;
}

#bookinfo.modal-dialog-centered.coverdesigner,
#covermodal.modal-dialog-centered.coverdesigner {
  max-height: 660px;
  top: 50%;
  left: 50%;
  margin-top: 0px !important;
  transform: translate(-50%, -50%) !important;
  margin-left: 0px !important;
  margin-right: 5px !important;
  max-width: 500px !important;
}

#bookinfo.modal-dialog-centered.coverdesigner.showhelp,
#covermodal.modal-dialog-centered.coverdesigner.showhelp {
  left: 0%;
  transform: translate(0%, -50%) !important;
}

.modal-dialog-centered.coverdesigner modal-content {
  display: flex;
}

@media (min-width: 750px) {
  .clipright {
    margin-left: calc(50% + 50px) !important;
  }
  .creatorish {
    transition: all 0.5s !important;
  }
}

.modal-dialog-centered.modal-lg.modal-contentabs.middlesize.creatorish.standalone {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  transform: translate(0%, 0%) !important;
  margin: 0px !important;
  margin-top: env(safe-area-inset-top) !important;
  margin-bottom: env(safe-area-inset-bottom) !important;
  height: unset !important;
  max-width: unset;
}

.eventspass {
  pointer-events: none;
}

@media (min-width: 1024px) {
  .clipleft {
    left: 5px !important;
    transform: translateX(0%) !important;
    transition: all 0.5s !important;
  }
  .clipright {
    margin-left: 750px !important;
  }
  .dimmerback {
    background-color: rgba(0, 0, 0, 0.2) !important;
  }
  .creatorish {
    transition: all 0.5s !important;
  }
}

@media (min-width: 1260px) {
  .clipleft {
    transition: all 0.5s;
    transform: translateX(0%) !important;
    left: calc(50% - 625px) !important;
    transition: all 0.5s !important;
  }
  .clipright {
    margin-left: calc(50% + 125px) !important;
  }
  .creatorish {
    transition: all 0.5s !important;
  }
}

.elementinsert {
  /* margin: calc(2rem + env(safe-area-inset-top)) auto
    calc(2rem + env(safe-area-inset-bottom)) auto !important; */
  margin-left: auto !important;
  margin-right: auto !important;
  overflow-y: hidden !important;
  transform: translateY(-50%) !important;
  top: 50%;

  max-width: 240px !important;
  max-height: 558px !important;
  display: flex;
  flex-direction: column;
}

.modal-dialog.elementinsert.showhelp {
  margin-left: 0px !important;
}

@media (min-width: 650px) {
  .modal-dialog.elementinsert.showhelp {
    margin-left: calc(50% - 320px) !important;
  }
}

@media (min-width: 1100px) {
  .modal-dialog.elementinsert.showhelp {
    margin-left: 280px !important;
  }
}

.modal-dialog.elementinsert .modal-content {
  display: flex !important;
  flex-direction: column !important;
  flex: 1 1 auto;
}
.modal-content {
  border-radius: 0px !important;
}
.browserish,
.helperish {
  border: 1px solid grey !important;
}

.helperish {
  max-height: 645px;
}

@media (min-height: 700px) {
  .modal-dialog.insertdialog {
    margin-top: calc(env(safe-area-inset-top) + 25px) !important;
    margin-bottom: calc(env(safe-area-inset-bottom) + 25px) !important;
    height: calc(
      100% - env(safe-area-inset-bottom) - env(safe-area-inset-bottom) - 50px
    ) !important;
  }
}

@media (max-height: 625px) {
  .auth-wrapper .auth-inner {
    background-color: transparent !important;
  }
}

.auth-inner.nodraggy {
  margin-top: calc(0px - env(safe-area-inset-top));
}

@media (max-width: 350px) {
  #appmain.auth-wrapper.auth-v1 {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
}

.itemdlg {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.itemdlg .modal-content {
  max-height: calc(100% - 25px);
}

.itemdlg .modal-content .modal-body {
  overflow: auto;
}

.itemdlg.showhelp {
  margin-left: 5px;
}

@media (min-width: 900px) {
  .itemdlg.showhelp {
    margin-left: calc(50% - 450px);
  }
}

@media (min-width: 1100px) {
  .itemdlg.showhelp {
    margin-left: 150px;
  }
}

.confirmer.showhelp {
  left: 0px !important;
}

@media (min-width: 900px) {
  .confirmer.showhelp {
    left: calc(50% - 450px) !important;
  }
}

@media (min-width: 1100px) {
  .confirmer.showhelp {
    left: 150px !important;
  }
}

.flexible-modal {
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  background: white;
}
.flexible-modal-mask {
  position: fixed;
  height: 0% !important;
  background: rgba(55, 55, 55, 0.6);
  top: 0;
  left: 0;
  right: unset !important;
  bottom: unset !important;
}
.flexible-modal-resizer {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  margin: 5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.flexible-modal-drag-area {
  background: rgba(22, 22, 333, 0.2);
  height: 30px !important;
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
  left: 0 !important;
  cursor: move;
}
