/* button {
  font-family: var(--font-normal) !important;
} */

/* body,
html,
#root {
  height: 100vh;
  font-family: var(--font-normal);
  overscroll-behavior-y: contain;
} */

#root {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
}

.react-joyride__spotlight {
  margin-top: calc(0px - env(safe-area-inset-top)) !important;
}

.__floater.__floater__open {
  margin-top: calc(0px - env(safe-area-inset-top)) !important;
}

#loaderdiv.ishidden {
  opacity: 0;
  transition: all 0s 0.15s;
}

#loaderdiv {
  opacity: 1;
  transition: all 0s 0.15s;
}

.greybox {
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.6);
  background: white;
}

#xm-typemodal .close.icon {
  top: -30px;
  right: -30px;
  color: white;
}

.select__menu-list {
  max-height: 400px !important;
}

@media (max-height: 800px) {
  .select__menu-list {
    max-height: 360px !important;
  }
}

@media (max-height: 750px) {
  .select__menu-list {
    max-height: 310px !important;
  }
}

@media (max-height: 700px) {
  .select__menu-list {
    max-height: 260px !important;
  }
}

@media (max-height: 600px) {
  .select__menu-list {
    max-height: 260px !important;
  }
}

@media (max-height: 550px) {
  .select__menu-list {
    max-height: 220px !important;
  }
}

.select__menu {
  z-index: 10000 !important;
}

html,
body {
  overflow: hidden;
}

.isgreen {
  background: #eeffee;
}

.isgreen:first-child {
  border-top: 1px solid black;
}

.flex111 {
  flex: 1 1 auto;
  overflow: hidden;
  display: flex !important;
  flex-direction: column !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0px !important;
  margin-bottom: 0px !important;
}

.flex111inv {
  flex: 1 1 auto;
  overflow: hidden;
  display: none !important;
  flex-direction: column !important;
}

.smoothcover {
  height: env(safe-area-inset-top);
  background: white;
  z-index: 99000;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
}

@media (prefers-color-scheme: dark) {
  .smoothcover {
    background: black;
  }
}

.mbsc-scroller-wheel-wrapper.mbsc-scroller-wheel-wrapper-1.mbsc-datetime-day-wheel.mbsc-material.mbsc-ltr {
  display: none !important;
}

#story input {
  padding: 3px;
  width: 100px;
  background: #f8f8f8;
  border-radius: 4px;
  padding-left: 12px;
  border: 1px solid lightgrey;
  outline: none;
}

.appmain {
  height: calc(100% - 60px);
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.appmain.scrollable {
  overflow: auto;
  display: block;
}

#appbody {
  flex: 1 1 auto;
  height: calc(100% - 90px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  transition: all 0.15s;
  opacity: 0;
}

button {
  -webkit-app-region: no-drag !important;
  user-select: none !important;
}

.draggy {
  -webkit-app-region: drag;
  user-select: none;
}

.nodraggy {
  -webkit-app-region: no-drag;
}

#appbody.visible {
  opacity: 1;
  transition: all 0.15s;
}

.appfooter {
  flex: 0 0 45px;
  padding: 3px;
  padding-top: 5px;
  background: lightgrey;
  box-shadow: 0px 0px 5px 0px #888888;
  z-index: 1;
}

/* @media (min-width: 650px) {
  body {
    background: var(--deep-background);
  }
} */

pwa-camera-modal-instance {
  z-index: 1051;
}

#mainmenu {
  opacity: 1;
}

#mainmenu.mfhidden {
  opacity: 0;
}

#dimmerlite.mfhidden {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s, z-index 0s 0.15s;
}

#xm-already.mfhidden {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s, z-index 0s 0.15s;
}

#xm-already {
  opacity: 1;
  transition: opacity 0.15s, z-index 0s 0.15s;
}

#helperdoc {
  opacity: 1;
  transition: opacity 0.1s, z-index 0s 0.1s;
}

#helperdoc.mfhidden {
  opacity: 0;
  transition: opacity 0.1s, z-index 0s 0.1s;
}

#xmsearch.mfhidden {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s, z-index 0s 0.15s;
}

#xmsearch {
  opacity: 1;
  transition: opacity 0.15s, z-index 0s 0.15s;
}

.appmain.mfhidden {
  opacity: 0;
  transition: 0.15s;
}

#chatcontainer {
  opacity: 1;
  transition: 0.15s;
}

#chatcontainer.mfhidden {
  opacity: 0 !important;
  transition: 0.15s;
}

#appheader.mfhidden {
  filter: blur(7px);
  transition: 0.15s;
}

#listdef.mfhidden {
  filter: blur(7px);
  transition: all 0.15s;
}

#listdef {
  transition: all 0.15s;
}

#appmain.mfhidden {
  opacity: 0;
  transition: 0.15s;
}

#claosd.mfhidden {
  opacity: 0;
  transition: 0.15s;
}

#claosd {
  opacity: 1;
  transition: 0.15s;
}

#exequestions.mfhidden {
  opacity: 0;
  transition: 0.15s;
}

#exequestions {
  transition: 0.15s;
}

#zactive.mfhidden {
  opacity: 0;
  transition: 0.15s;
}

#zactive {
  opacity: 1;
  transition: 0.15s;
}

.appmain {
  opacity: 1;
  transition: 0.15s;
}

#appheader {
  filter: blur(0px);
  transition: 0.15s;
}

#appmain {
  opacity: 1;
  transition: 0.15s;
  min-height: calc(
    100% - env(safe-area-inset-top) - env(safe-area-inset-bottom)
  ) !important;
}

#appmain.mainer {
  height: calc(
    100% - env(safe-area-inset-top) - env(safe-area-inset-bottom)
  ) !important;
}

@media (max-width: 1000px) {
  #appmain {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px;
    padding-right: 0px;
  }

  #appmain .content-area-wrapper {
    height: 100% !important;
  }
}

#exlist {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

#dimmerlite {
  opacity: 1;
  transition: opacity 0.15s, z-index 0s;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  user-select: none;
}

#dimmerlite.fixer {
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow-y: auto;
}

.smoothscroll {
  -webkit-overflow-scrolling: touch;
  height: 100%;
  overflow: auto;
}

/* @media (min-width: 813px) {
  #dimmerlite {
    background-color: var(--deep-background);
  }
} */

/* @media (prefers-color-scheme: dark) {
  #dimmerlite {
    background: black;
  }
} */

.demo-inline-spacing2 > * {
  margin-right: 8px;
  margin-bottom: 8px;
}

.borderedsort {
  min-height: 80px;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #eeeeee;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.fw-700 {
  font-weight: 700;
}

.text-toolbar {
  color: var(--toolbar-text) !important;
}

.text-toolbarbg {
  color: var(--toolbar-background) !important;
}

.bg-lightgrey {
  background: lightgrey !important;
  color: grey !important;
}

.text-dark-toolbar {
  color: var(--toolbar-dark-text) !important;
}

.bg-toolbar.bg-toolbar {
  background-color: var(--toolbar-background) !important;
  border-color: var(--toolbar-text) !important;
}

.border-toolbar {
  border-color: var(--toolbar-background) !important;
}

.toolbar-button {
  background-color: var(--toolbar-background) !important;
  border-color: var(--toolbar-background) !important;
  color: var(--toolbar-text) !important;
}

:root {
  --toolbar-text: #fff501;
  --toolbar-dark-text: #111111;
  --toolbar-background: #21409a;
  --toolbar-color: rgba(255, 255, 255, 0.7);
  --player-background: #ffdebf;
  --player-color: rgba(0, 0, 0, 0.7);
  --text-color: #3c4858;
  --bold-color: #ffffff;
  --deep-background: #172b4d;
  --paper-color: "white";
  --done-background: #eeffee;
  --active-background: #ffeeee;
  --pending-background: #ffffee;
  --list-main-color: #000000;
  --list-secondary-color: #808080;
  --icon-color: rgba(0, 0, 0, 0.74);
  --icon-shadow: rgba(255, 255, 255, 0.74);
  --black-icon-color: rgba(0, 0, 0, 0.8);
  --black-icon-shadow: rgba(0, 0, 0, 0.7);
  --mail-text: rgba(255, 255, 255, 0.7);
  --mail2-text: rgba(0, 0, 0, 0.7);

  --toolbar-background-bg: #ffdebf;
  --theme-color: #e76659;
  --toolbar-background-dark: #e76659;
  --toolbar-background-light: #ffdebf;
  --theme-disabled: #d4d4d4;
  --line-color: #b2b2b2;
  --icon-color: #898989;
  --secondary-color: #898989;
  --table-color: #e9e9e9;
  --secondary-disabled: #bebebe;
  --secondary-hover: #5e5e5e;
  --header-color: #fafafa;
  --second-avatar: #b23b3b;
  --first-avatar: #618bbb;
  --line-grey: #dddddd;
  --green: #4caf50;
  --once-icon: #00bcd4;

  --green-light: #4caf5052;
  --font-normal: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

body {
  position: fixed;
  top: env(safe-area-inset-top);
  left: 0px;
  right: 0px;
  bottom: 0px;
}

body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 10px;
}

@media (min-width: 250px) {
  div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px grey;
    background-color: transparent;
  }

  div::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }

  div::-webkit-scrollbar-thumb {
    background-color: #bbbbbb;
    border: 1px solid #bbbbbb;
    opacity: 0.5;
  }
}

.hidebig {
  width: 100% !important;
}

@media only screen and (min-width: 500.01px) {
  .hidebig {
    display: none !important;
    width: 100% !important;
  }
  .spec-dropdown {
    list-style: none;
  }
}

@media only screen and (min-width: 768px) {
  div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px transparent;
    background-color: transparent;
  }

  div::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }

  div::-webkit-scrollbar-thumb {
    background-color: #bbbbbb;
    border: 1px solid #bbbbbb;
  }
}

.CodeMirror.CodeMirror-wrap {
  height: 100%;
}

.xl-lined {
  border-bottom: 1px dotted lightgrey;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.keyclass:focus {
  outline: none !important;
}

.xm-ontop {
  z-index: 50000;
}

.ui.dimmer.ui.dimmer {
  padding: 0px;
}

#xupdown.xvisible {
  opacity: 1;
  transition: all 0.3s;
}

.ui.tiny.modal {
  max-width: 500px;
}

.bold {
  color: var(--toolbar-background);
  font-weight: 700;
}

#chattyper .ql-editor {
  padding: 0px 8px 0px 8px;
  font-size: 16px;
  border-radius: 3px;
  word-wrap: break-word;
}

#chattyper .ql-container {
  border-radius: 15px;
  padding-left: 5px;
  max-height: 100px;
  overflow: auto;
}

.sortableHelper {
  z-index: 150000 !important;
  background: rgba(255, 255, 255, 0.7);
}

#write .ql-container {
  flex: 1 1 auto;
  overflow: auto;
}

#list5 .ql-container {
  flex: 1 1 auto;
  overflow: auto;
}

.topulsate {
  background: white;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  animation: pulse-white 2s infinite;
}

@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes delayed {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

p {
  margin-bottom: 3px;
}

.scrolling.dimmable > .dimmer {
  justify-content: center !important;
}

.xl-hled {
  box-shadow: 0px 0px 15px 1500px rgba(0, 0, 0, 0.37);
  transition: all 0.3s;
  z-index: 500000;
  outline: auto;
}

.videoing:hover {
  box-shadow: 0px 0px 15px 1500px rgba(0, 0, 0, 0.37) !important;
  transition: all 0.3s ease 1s !important;
  z-index: 500000;
  outline: auto;
}

.videoing {
  transition: all 0.3s;
  z-index: 5;
}

.xl-base {
  transition: all 0.3s;
  z-index: 500000;
}

.leftfloat {
  float: "left";
}

.termbold {
  color: var(--toolbar-background);
  font-weight: bold;
}

.collapsedme {
  display: none;
}

html .page .content {
  position: static;
  margin-left: 0px;
}

html #emailer .content {
  position: static;
  margin-left: 0px;
}

html #useradding .content {
  position: static;
  margin-left: 0px;
}

html #creatorxa .content {
  position: static;
  margin-left: 0px;
}

html #bging .content {
  position: static;
  margin-left: 0px;
}

html #tinput .content {
  position: static;
  margin-left: 0px;
}

#tinput {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

html .bookpublisher .content {
  position: static;
  margin-left: 0px;
}

html #clipperx .content {
  position: static;
  margin-left: 0px;
}

html #appmain .content {
  position: static;
  margin-left: 0px;
}

html #coversave .content {
  position: static;
  margin-left: 0px;
}

@media (max-width: 513px) {
  html #xxloader {
    margin-left: auto;
    margin-right: auto;
  }

  #xxcovertools {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  #xxeditcover {
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }
}

#xxpara .btn-sm,
.btn-group-sm > .btn {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

html #xxloader .content {
  position: static;
  margin-left: 0px;
}

html #bookinfo2 .content {
  position: static;
  margin-left: 0px;
}

/* @media (min-width: 576px) {
  .outmaxed {
    max-width: 85%;
  }
} */

.react-contexify {
  z-index: 50332 !important;
}

.react-contexify .react-contexify__submenu {
  bottom: -6px !important;
  top: initial !important;
  max-height: 200px;
  overflow: auto;
}

@media print {
  html body {
    overflow: visible !important;
  }
  body #root {
    overflow: visible !important;
  }
  body #appmain#appmain#appmain {
    overflow: visible !important;
  }
  body * {
    visibility: hidden;

    overflow: visible !important;
  }

  #recordid {
    display: none;
  }

  #blockviewer,
  #blockviewer * {
    visibility: visible;
    overflow: visible !important;
  }
  #blockviewer {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    background: white;
  }

  #turnerx {
    max-width: 950px;
  }
}

.Toastify__toast--default {
  padding: 0px !important;
}

.Toastify__toast-body {
  padding: 0px !important;
}

.Toastify__toast {
  border: none !important;
}

.Toastify__close-button {
  position: absolute !important;
  top: 3px !important;
  right: 8px !important;
}

.pickerclass .react-datetime-picker__wrapper {
  padding: 5px;
  border-radius: 3px;
  border-color: lightgrey;
}

#react-joyride-portal:empty {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

/* @media (min-height: 880px) {
  .modal-video-body {
    max-width: 750px;
  }
}

@media (min-height: 750px) {
  .modal-video-body {
    max-width: 673px;
  }
}

@media (min-height: 700px) {
  .modal-video-body {
    max-width: 638px;
  }
}

@media (min-height: 650px) {
  .modal-video-body {
    max-width: 600px;
  }
}

@media (min-height: 600px) {
  .modal-video-body {
    max-width: 570px;
  }
}

.modal-video-body {
  padding: 0px 5px !important;
}

@media (orientation: landscape) {
  .modal-video-inner {
    padding: 10px 29px !important;
  }
} */

.loafer {
  transition: height 0.3s;
}

.custom-control-input:checked ~ .custom-control-label:before {
  background-color: var(--toolbar-background) !important;
  border-color: var(--toolbar-background) !important;
  color: var(--toolbar-dark-text);
}

#exequestions .modal {
  top: unset;
  left: unset;
  height: unset;
}

#publisherx .tab-content {
  overflow-y: hidden;
  overflow-x: hidden;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

#publisherx .tab-pane {
  overflow-y: hidden;
  overflow-x: hidden;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

#publisherx .tab-pane.secer {
  padding-right: 1.4rem;
}

@media (min-width: 1024px) {
  #blockviewer.huger {
    padding: 30px 50px 30px 50px;
  }
}

@media (min-width: 1200px) {
  #blockviewer.huger {
    padding: 30px 100px 30px 100px;
  }
}

@media (min-width: 1400px) {
  #blockviewer.huger {
    padding: 30px 150px 30px 150px;
  }
}

@media (min-width: 1700px) {
  #blockviewer.huger {
    padding: 30px 300px 30px 300px;
  }
}

@media (min-width: 1900px) {
  #blockviewer.huger {
    padding: 30px 300px 30px 300px;
  }
}

.opacity-75 {
  opacity: 0.75;
}

p.lineit {
  font-size: 16px;
}

.subtit {
  font-size: 18px;
  font-weight: bold;
  color: var(--toolbar-background);
  margin-top: 15px;
  margin-bottom: 10px;
}

.kriklass {
  width: 80%;
  text-align: center;
  position: relative;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 500px) {
  .kriklass {
    width: 80%;
  }
}

.kripic {
  position: absolute;
  top: 0px;
  border: 1px solid lightgrey;
  left: 50%;
  transform: translateX(-50%);
}

#helptour p {
  font-size: 16px;
}

.worder p {
  line-height: 1.3;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 16px;
  overflow-y: auto;
}

@media (orientation: landscape) {
  .modal-video-close-btn {
    top: 0;
    right: -45px;
  }
}

@media (max-width: 620px) {
  .modal-video-close-btn {
    top: unset;
    bottom: 5px;
    right: 5px;
    background: black;
    border-radius: 4px;
    opacity: 0.8;
  }
  .modal-video-close-btn:after,
  .modal-video-close-btn:before {
    margin-top: -2px !important;
  }
}

.fullscreenmd {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: white;
}
