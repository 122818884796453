.xl-subheader,
.xl-small {
  padding: 15px 20px 15px 20px !important;
  font-size: 115% !important;
  font-weight: bold !important;
  line-height: 1.4;
}

.ql-editor {
  font-size: 16px !important;
}

.xl-error {
  padding: 15px 20px 25px 20px;
  font-size: 115%;
  font-weight: bold;
  color: red;
}

.xl-header,
.xl-big {
  padding: 15px 20px 25px 20px !important;
  line-height: 1.4;
  font-size: 130% !important;
  font-weight: bold !important;
  color: var(--toolbar-background) !important;
}

.xl-psentence {
  padding: 15px 30px 6px 30px !important;
  font-size: 16px;
  line-height: 1.9 !important;
}

.xl-normal {
  padding: 3px 20px 6px 20px !important;
  font-size: 16px;
  line-height: 1.6 !important;
}

.xl-titletop {
  padding: 15px 10px 15px 10px !important;
  background: lightgrey;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.6 !important;
}

.xl-translation {
  padding: 6px 30px 6px 30px !important;
  line-height: 1.6 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  margin: 0px !important;
}

.xl-essay {
  padding: 15px 20px 5px 20px !important;
  font-size: 105% !important;
}

.xl-normal:first-of-type {
  padding-top: 15px !important;
}

.xl-boldnormal {
  padding: 3px 20px 6px 20px !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 1.6 !important;
}

.xl-boldnormal:first-of-type {
  padding-top: 15px !important;
}

.xl-question {
  padding: 15px 30px 6px 30px !important;
  font-size: 16px;
  line-height: 1.6 !important;
}

.xl-questionc {
  padding: 15px 23px 6px 23px;
  line-height: 1.8;
}

.xl-question:first-of-type {
  padding-top: 25px;
}

.xl-notification {
  background: #eeeeee;
  box-shadow: 1px 1px 2px 2px #999999;
  padding: 6px 20px 6px 20px !important;
  line-height: 1.8;
}

.xl-center {
  text-align: center;
  font-weight: bold;
  color: var(--toolbar-background);
  margin-top: 25px;
  margin-bottom: 50px;
}

.xl-testq {
  font-weight: bold;
  color: var(--toolbar-background);
  padding: 20px 20px 15px 20px;
  line-height: 1.4;
}

.xl-translation1 {
  padding: 15px 30px 6px 30px;
  line-height: 1.4;
  font-weight: 500;
  border-top: 1px solid #f2f2f2;
}

.cap-player {
  position: absolute;
  top: 0;
  left: 0;
}

.exe-wrapper {
  line-height: 1.4;
}

.exe-questions {
  padding: 20px;
  overflow: auto;
  flex: 1 1 auto;
  border-top: 1px solid lightgrey;
  font-size: 18px;
}

.xl-cap,
.xl-selector {
  padding-bottom: 22px;
  line-height: 1.4;
}

.xl-cap:first-of-type,
.xl-selector:first-of-type {
  padding-top: 22px;
  padding-bottom: 22px;
  line-height: 1.4;
}

.xl-q {
  padding: 15px 20px 15px 20px;
  font-size: 18px;
  background-color: papayawhip;
  border-bottom: 1px solid lightgrey;
  position: sticky;
  top: 0px;
  z-index: 400;
}

.xl-qa {
  padding: 15px 20px 15px 20px;
  font-size: 18px;
  background-color: papayawhip;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  z-index: 400;
}

.xl-q1 {
  padding: 20px 30px 15px 20px;
  font-size: 18px;
}

.xl-questions {
  padding: 35px 20px 20px 20px;
  font-size: 18px;
  font-weight: bold;
  border-top: 1px solid lightgrey;
  margin-top: 15px;
}

.exe-tags {
  padding: 0px 15px 0px 10px;
  background: lightgrey;
  font-size: 11px;
  display: flex;
  min-height: 50px;
  line-height: 150%;
  flex: 0 0 auto;
  align-items: center;
  box-shadow: 0px 0px 8px 0px #888888;
  z-index: 1;
}

.input-wrap {
  position: relative;
  display: inline-block;
  min-width: 65px;
  margin-right: 6px;
  margin-left: 6px;
  top: 2px;
}

.input-wrap input {
  position: absolute;
  width: 100%;
  left: 0;
  padding: 2px 5px 2px 5px;
  line-height: 140%;
  top: -2px;
  border: 0px solid transparent !important;
  border-bottom: 1px solid black !important;
  background: #eee;
  border-radius: 0px;
  text-align: center;
  font-size: 20px;
  font-family: Raleway;
}

.input-wrap input:focus {
  outline: none;
  background-color: #f4f4f4;
}

.width-machine {
  /*   Sort of a magic number to add extra space for number spinner */
  padding: 0 1rem;
  display: inline-block;
  min-width: 60px;
  white-space: nowrap;
  color: white;
}
