:root {
  --side-bar-bg-color: #fafafa;
  --control-text-color: #777;
  --monospace: "Jetbrains Mono", "思源黑体 CN";
}

#write > ul:first-child,
#write > ol:first-child {
  margin-top: 30px;
}

#chattyper p {
  margin-top: 4px;
  margin-bottom: 4px;
}

#write h1,
#write h2,
#write h3,
#write h4,
#write h5,
#write h6 {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 700;
  line-height: 1.4;
  cursor: text;
  font-feature-settings: "tnum";
}

.md-toc {
  font-weight: 500;
  line-height: 1.2;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  font-feature-settings: "tnum";
}

#write h1:hover a.anchor,
#write h2:hover a.anchor,
#write h3:hover a.anchor,
#write h4:hover a.anchor,
#write h5:hover a.anchor,
#write h6:hover a.anchor {
  text-decoration: none;
}

#write h1 tt,
#write h1 code {
  font-size: inherit;
}

#write h2 tt,
#write h2 code {
  font-size: inherit;
}

#write h3 tt,
#write h3 code {
  font-size: inherit;
}

#write h4 tt,
#write h4 code {
  font-size: inherit;
}

#write h5 tt,
#write h5 code {
  font-size: inherit;
}

#write h6 tt,
#write h6 code {
  font-size: inherit;
}

#write h1 {
  font-family: "Inter", "思源黑体 CN";
  padding-bottom: 0.1em;
  font-size: 2.25em;
  line-height: 1.5;
}

#write h1 strong {
  font-weight: 800;
}

#write li.active {
  background-color: #000;
  color: #fff;
}

#write h3 {
  font-size: 1.5em;
  line-height: 1.43;
}

#write h4 {
  font-size: 1.25em;
}

#write h5 {
  font-size: 1em;
  line-height: 2;
}

#write h5 strong {
  background-color: #3b78e7;
  color: white;
  font-weight: 800;
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center;
  line-height: 24px;
  padding: 0;
  font-family: Inter;
  font-feature-settings: "tnum" 0;
  border-radius: 3px;
}

#write h6 {
  font-size: 1em;
}

#write p,
#write blockquote,
#write ul,
#write ol,
#write dl,
#write table {
  margin: 0.8em 0;
}

#write li > ol,
#write li > ul {
  margin: 2 0;
}

#write hr {
  height: 2px;
  padding: 0;
  margin: 16px 0;
  background-color: #e7e7e7;
  border: 0 none;
  overflow: hidden;
  box-sizing: content-box;
}

#write li p.first {
  display: inline-block;
}

#write ul,
#write ol {
  padding-left: 30px;
}

#write ul:first-child,
#write ol:first-child {
  margin-top: 0;
}

#write ul:last-child,
#write ol:last-child {
  margin-bottom: 0;
}

#write a > span > code {
  color: #3b78e7 !important;
  text-decoration: none !important;
}

#write blockquote {
  border-radius: 4px;
  padding: 12px 16px;
  background-color: #f4f7f8;
  font-family: "思源宋体 CN", serif;
  color: #252d34;
}

#write blockquote code {
  background-color: transparent;
}

#write blockquote blockquote {
  padding-right: 0;
}

#write table {
  padding: 0;
  word-break: initial;
}

#write table tr {
  border-top: 1px solid #dfe2e5;
  margin: 0;
  padding: 0;
}

#write table tr:nth-child(2n),
thead {
  background-color: #f8f8f8;
}

#write table tr th {
  font-weight: bold;
  border: 1px solid #dfe2e5;
  border-bottom: 0;
  margin: 0;
  padding: 6px 13px;
}

#write table tr td {
  border: 1px solid #dfe2e5;
  margin: 0;
  padding: 6px 13px;
}

#write table tr th:first-child,
#write table tr td:first-child {
  margin-top: 0;
}

#write table tr th:last-child,
#write table tr td:last-child {
  margin-bottom: 0;
}
