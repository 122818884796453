@import "~nouislider/distribute/nouislider.css";
@import "../../../base/plugins/extensions/ext-component-sliders.scss";
@import "../../../base/core/colors/palette-noui";

html[dir="rtl"] .noUi-horizontal {
  .noUi-origin {
    left: auto !important;
    right: 0 !important;
    .noUi-handle {
      right: -10px;
      left: auto;
    }
  }
  .noUi-connects {
    .noUi-connect {
      left: 0;
      transform-origin: 100% 100%;
    }
  }
  .noUi-value-horizontal {
    transform: translate(-50%, 50%);
  }
}
