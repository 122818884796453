* {
  box-sizing: border-box;
}

.mycard {
  flex: 1 1 50%;
  padding: 5px;
  padding-bottom: 25px;
}

.mycard img {
  width: 100%;
  height: 100%;
}

.mycard > .mycard-body {
  height: 100%;
  overflow: auto;
}
